import http from "./httpService";

const apiEndpoint = "/services/app/AfasLog";

export function GetLogs(
    maxResultCount: number,
    skipCount: number,
    isError: boolean
)
{
        return http.get<any>(apiEndpoint + "/GetAllAfasLogRecord", { 
        params: { maxResultCount, skipCount, isError },

        });
};