import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Define the callback type for fetching data
type FetchDataCallback = (
  year: number,
  week: number,
  weekPart: number,
  fullDate: string
) => void;

const useQuickNavigationParams = (fetchDataCallback?: FetchDataCallback) => {
  const [searchParams] = useSearchParams();
  const [week, setWeek] = useState<number>(moment().isoWeek());
  const [weekPart, setWeekPart] = useState<number>(1);
  const [year, setYear] = useState<number>(moment().year());
  const [fullDate, setFullDate] = useState<string>(
    moment().endOf("isoWeek").toISOString()
  );

  useEffect(() => {
    let newYear: number;
    let newWeek: number;
    let newWeekPart: number;
    let newFullDate: string;

    if (searchParams.has("date")) {
      // Fetch 'date' from searchParams (format: DD-MM-YYYY)
      const dateStr = searchParams.get("date")!;
      const parsedDate = moment(dateStr, "DD-MM-YYYY");

      if (parsedDate.isValid()) {
        newYear = parsedDate.year();
        newWeek = parsedDate.isoWeek();
        newWeekPart = parsedDate.date() <= 15 ? 1 : 2;
        newFullDate = parsedDate.toISOString();
      } else {
        console.warn("Invalid date format in searchParams:", dateStr);
        return;
      }
    } else {
      // Default to week-based navigation
      newWeek = searchParams.has("week")
        ? Number(searchParams.get("week"))
        : moment().isoWeek();
      newWeekPart = searchParams.has("weekpart")
        ? Number(searchParams.get("weekpart"))
        : 1;
      newYear = searchParams.has("year")
        ? Number(searchParams.get("year"))
        : moment().year();

      newFullDate = moment()
        .year(newYear)
        .isoWeek(newWeek)
        .endOf("isoWeek")
        .toISOString();
    }

    setWeek(newWeek);
    setWeekPart(newWeekPart);
    setYear(newYear);
    setFullDate(newFullDate);

    if (fetchDataCallback) {
      fetchDataCallback(newYear, newWeek, newWeekPart, newFullDate);
    }
  }, [searchParams, fetchDataCallback]);

  return { week, weekPart, year, fullDate };
};

export default useQuickNavigationParams;
