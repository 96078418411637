import { useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button } from "reactstrap";
import AppFormLabel from "./AppFormLabel";

export const AppSignaturepad = ({ name, label, required }) => {
  const { setFieldValue } = useFormikContext();

  const [width, setWidth] = useState(500);

  let sigCanvas = useRef();
  const signatureContainer = useRef();

  useEffect(() => {
    setWidth(signatureContainer.current.clientWidth);
  });

  const clearCanvas = () => {
    setFieldValue(name, "");
    sigCanvas.current.clear();
  };

  const saveCanvas = async () => {
    if (sigCanvas.current.isEmpty()) return;
    setFieldValue(
      name,
      sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  return (
    <div>
      <AppFormLabel for="floatingInput" label={label} required={required} />

      <div>
        <div className={"sigContainer"} ref={signatureContainer}>
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{ width: width, height: 300, className: "sigPad" }}
            onEnd={saveCanvas}
          />
        </div>
        <Button type={"button"} onClick={clearCanvas} children={"Clear"} />
        <br />
        <br />
      </div>
    </div>
  );
};
