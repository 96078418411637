import { Formik, FormikProps } from "formik";
import * as moment from "moment";
import React, { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Form,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import {
  getSignoffDaily,
  submitSignOffDaily,
} from "../../services/dailyService";
import { DailyStatus, GetDailyDto, SignOffInput } from "../../types/apiTypes";
import { Daily_status_types } from "../../utility/constants";
import useQuickNavigationParams from "../../utility/hooks/useQuickNavigationParams";
import Message from "../../utility/notifications/Message";
import AppButton from "../components/Common/AppButton";
import DatePickerFilter from "../components/Common/DateFilterPickr";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import HeaderBanner from "../components/banners/HeaderBanner";
import { AppSignaturepad } from "../components/forms/AppSignaturepad";
import AppTextAreaInput from "../components/forms/AppTextAreaInput";
import AppTextInput from "../components/forms/AppTextInput";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";

const validationSchema = Yup.object().shape({
  comment: Yup.string().max(1000).label("Comment"),
});

const SignoffDaily = ({ id }: SignoffDailyProps) => {
  const [searchParams] = useSearchParams();
  const [filterDate, setFilterDate] = useState(moment().toDate());

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState("accordionItem1");
  const [dataEntryComplete, setDataEntryComplete] = useState(false);
  const [dailyReportData, setDailyReportData] = useState<GetDailyDto>();

  const handleNextPrevDate = (isPrevious) => {
    if (isPrevious) {
      const newDate = moment(filterDate).clone().endOf("day").add(-1, "days");
      getDailyData(id, newDate.toISOString());
      setFilterDate(newDate.toDate());
    } else {
      const newDate = moment(filterDate).clone().endOf("day").add(1, "days");
      getDailyData(id, newDate.toISOString());
      setFilterDate(newDate.toDate());
    }
  };

  const fetchData = useCallback(
    (year, week, weekPart, fullDate) => {
      if (id) {
        getDailyData(id, fullDate);
        setFilterDate(fullDate);
      }
    },
    [id]
  );

  useQuickNavigationParams(fetchData);

  const handleDatePickerChange = (newDate) => {
    setFilterDate(newDate.date);

    getDailyData(id, newDate.date);
  };

  function toggle(newIndex) {
    if (newIndex === index) {
      setIndex("accordionItem0");
    } else {
      setIndex(newIndex);
    }
  }

  const isEditingDisabled = (status, isApproved) => {
    var dailyStatus = ![Daily_status_types.SendForReview].includes(status);

    if (dailyStatus) {
      return true;
    }

    if (isApproved) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (data: SignOffInput, { setSubmitting }) => {
    await submitSignOffDaily(data).then(() => {
      setDataEntryComplete(false);
      setSubmitting(false);
      getDailyData(id, filterDate);
    });
  };

  async function getDailyData(projectId, date) {
    setLoading(true);
    const result = await getSignoffDaily(projectId, date);

    setDailyReportData(result.data.result);
    setLoading(false);
  }

  return (
    <>
      {!loading && (
        <>
          <div className="flex flex-row d-inline">
            <PageTitle
              title={dailyReportData?.projectName || ""}
              customStyles="mb-2"
            >
              <DatePickerFilter
                handleChange={(date) => handleDatePickerChange({ date: date })}
                classNames="ms-2"
              />
            </PageTitle>
          </div>

          <DateToggler
            title={`${moment(filterDate).format(
              "dddd | DD-MM-yyyy | "
            )} week ${moment(filterDate).isoWeek()}`}
            onNextDate={() => handleNextPrevDate(false)}
            onPreviousDate={() => handleNextPrevDate(true)}
          />
        </>
      )}
      {dailyReportData ? (
        <>
          {dailyReportData &&
            dailyReportData.status === DailyStatus.Value14 && (
              <HeaderBanner type="info" title="The daily has been approved" />
            )}

          {dailyReportData &&
            (dailyReportData.status ===
              Daily_status_types.DeclinedByAreaManager ||
              dailyReportData.status ===
                Daily_status_types.IsDeclinedByMOD) && (
              <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm">
                <div className="lh-1">
                  <h1 className="h6 mb-0 text-white lh-1">
                    The daily sheet has been denied
                  </h1>
                  <small>
                    The following comment was added: {dailyReportData.comment}
                  </small>
                </div>
              </div>
            )}

          <Formik
            enableReinitialize={true}
            initialValues={
              {
                dailyItems: dailyReportData.dailyItems,
                projectId: dailyReportData.projectId,
                date: dailyReportData.date,
                comment: "",
                sendForReview: false,
                signature: "",
              } as GetDailyDto
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              values,
              setFieldValue,
              isSubmitting,
            }: FormikProps<GetDailyDto>) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Accordion open={index} toggle={toggle}>
                    <AccordionItem>
                      <AccordionHeader targetId="accordionItem1">
                        {dailyReportData.projectName}
                      </AccordionHeader>

                      <AccordionBody accordionId="accordionItem1">
                        <Row>
                          <Col lg={8} sm={8}>
                            <h6>Category</h6>
                          </Col>
                          <Col lg={4} sm={4}>
                            <h6>Amount</h6>
                          </Col>
                        </Row>

                        {values.dailyItems &&
                          values.dailyItems.map((item, i) => {
                            return (
                              <Row className="mb-4" key={i}>
                                <Col lg={8} sm={8}>
                                  <AppTextInput
                                    type="text"
                                    name={`dailyItems[${i}].itemName`}
                                    disabled
                                  />
                                </Col>
                                <Col lg={4} sm={4}>
                                  <AppTextInput
                                    disabled={true}
                                    type="number"
                                    name={`dailyItems[${i}].value`}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>

                  {dailyReportData.comments && (
                    <>
                      <h5 className="mt-3">Comments</h5>
                      {dailyReportData.comments.map((comment) => (
                        <MessageTimeLineItem
                          action={comment.type}
                          userName={comment.userName}
                          value={comment.value}
                          isAdditionalRequest={comment.isAdditionalRequest}
                          creationTime={new Date(comment.creationTime)}
                        />
                      ))}{" "}
                    </>
                  )}

                  <>
                    {!isEditingDisabled(dailyReportData.status, false) && (
                      <>
                        <Row>
                          <h4>Info</h4>
                          <Col sm={12}>
                            <div className="mb-3">
                              <AppTextAreaInput
                                name="comment"
                                label="Comment"
                                disabled={isEditingDisabled(
                                  dailyReportData.status,
                                  false
                                )}
                                rows="6"
                              />
                            </div>
                          </Col>

                          <Col className="mt-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              value="1"
                              onClick={(e) => {
                                setDataEntryComplete(e.target.checked);
                              }}
                              id="dataEntryCorrect"
                            />

                            <label
                              htmlFor="dataEntryCorrect"
                              className="form-check-label ms-2"
                            >
                              To my knowledge, this form has been filled in
                              correctly
                            </label>
                          </Col>
                        </Row>

                        <AppSignaturepad
                          required={false}
                          name="signature"
                          label="Signed by (official title)"
                        />

                        <Row className="text-end pt-5">
                          <Col>
                            <Button
                              loading={isSubmitting}
                              disabled={isSubmitting}
                              className="me-3"
                              variant="cancel"
                              color="danger"
                              size="lg"
                              title="Decline"
                              buttonSize="lg"
                              type="button"
                              onClick={(e) => {
                                setFieldValue("isApproved", false);

                                if (
                                  values.comment == "" &&
                                  values.comment != null
                                ) {
                                  Message(
                                    "Comment is required when you decline a daily"
                                  );
                                } else {
                                  handleSubmit(e);
                                }
                              }}
                            >
                              Decline
                            </Button>

                            <AppButton
                              disabled={
                                isSubmitting ||
                                !dataEntryComplete ||
                                !(values as any).signature
                              }
                              loading={isSubmitting}
                              type="submit"
                              variant="submit"
                              title="Signoff"
                              buttonSize="lg"
                              onClick={(e) => {
                                setFieldValue("isApproved", true);
                                handleSubmit(e);
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                </Form>
              </>
            )}
          </Formik>
        </>
      ) : (
        "No data found..."
      )}
    </>
  );
};

export default SignoffDaily;
