import { Formik } from "formik";
import PageTitle from "../components/Common/PageTitle";
import AppCheckboxInput from "../components/forms/AppCheckboxInput";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import Notify from "../../utility/notifications/Notify";
import { IsDailyExportAutomationOn, turnOnDailyExportAutomation } from "../../services/hostDashboardService";
import AppButton from "../components/Common/AppButton";
import React from "react";
import { Col, Row, Form } from "react-bootstrap";

const validationSchema = Yup.object().shape({
  isActive: Yup.bool().required().label("Is active"),
});

const HostSetting = () => {
  const [isActive, setIsActive] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await IsDailyExportAutomationOn();
      setIsActive(result.data.result === "True");
    };
    fetchData();
  }, []);

  const handleSubmit = async (values: { isActive: boolean }) => {
    await turnOnDailyExportAutomation(values.isActive);
    Notify.success("Settings have been successfully updated.");
  };

  if (isActive === null) return <div>Loading...</div>;

  return (
    <>
      <PageTitle title={"General Settings"} />
      <Row className="text-center">
        <h6>
          You can modify the general application settings on this page. These settings allow you to control the automatic export functionality for your daily data.
        </h6>
      </Row>
      <Formik
        initialValues={{ isActive }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-4 mt-4">
              <Col sm={12}>
                <h4>Netsuite Settings</h4>
              </Col>

              <Col sm={12}>
                <AppCheckboxInput
                  name="isActive"
                  label="Enable automatic daily export to NetSuite."
                />
              </Col>
            </Row>

            <Row className="text-end">
              <Col>
                <AppButton
                  title={"Update Settings"}
                  variant="save"
                  buttonSize="lg"
                  type="submit"
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default HostSetting;
