import { Comment, GetHostDashboardData, MatrixData } from "../types";
import http from "./httpService";

const apiEndpoint = "/services/app/HostWeeklyDashboard";
const apiEndpointExport = "/services/app/AfasExport";

export function getStatistics(
  tenantIds: number[],
  projectIds: number[],
  year: number,
  week: number,
  weekPart: number
) {
  return http.get<GetHostDashboardData>(apiEndpoint + "/GetStatistics", {
    params: { projectIds, year, week, weekPart, tenantIds },
  });
}

export function getProjectAfasExport(
  projectId: number,
  week: number,
  weekPart: number,
  year: number
) {
  return http.get(apiEndpointExport + "/Export", {
    params: { projectId, week, weekPart, year },
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    timeout: 30000,
  });
}

export function getAfasExportByRange(
  projectIds: number[],
  dateFrom: Date,
  dateTill: Date,
  itemIds?: number[],
  additionalItemids: number[],
  staypleaseItemids: number[]
) {
  return http.get(apiEndpointExport + "/ExportByDateRange", {
    params: {
      projectIds,
      dateFrom,
      dateTill,
      itemIds,
      additionalItemids,
      staypleaseItemids,
    },
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    timeout: 30000,
  });
}

export function getExportMatrix(week: number, weekPart: number, year: number) {
  return http.get(
    "/services/app/StaypleaseDailyExport/ExportDailyStatusMatrix",
    {
      params: {
        week,
        weekPart,
        year,
      },
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
      timeout: 30000,
    }
  );
}

export function getMatrixData(year: number, week: number, weekPart: number) {
  return http.get<any>(
    "/services/app/HostWeeklyDashboard/GetDailyStatusMatrix",
    {
      params: {
        week,
        weekPart,
        year,
      },
      headers: {
        "Content-Type": "application/json",
      },
      timeout: 30000,
    }
  );
}

export function getProjectExportToAfas(
  projectId: number,
  week: number,
  weekPart: number,
  year: number
) {
  return http.get(apiEndpointExport + "/ExportToAfas", {
    params: { projectId, week, weekPart, year },
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getProjectExportToNetSuite(projectId, week, weekPart, year) {
  return http.post("/services/app/NetSuiteExportService/ExportToNetSuite", {
    projectId: projectId,
    week: week,
    weekPart: weekPart,
    year: year,
  });
}

export function turnOnDailyExportAutomation(isActive) {
  return http.post(`/services/app/NetSuiteExportService/TurnOnDailyExportAutomation?value=${isActive}`);
}

export function IsDailyExportAutomationOn() {
  return http.get<any>("/services/app/NetSuiteExportService/IsDailyExportAutomationOn");
}


export function getComments(
  projectId: number,
  year: number,
  week: number,
  weekPart: number
) {
  return http.get<Comment[]>(apiEndpoint + "/GetComments", {
    params: { projectId, year, week, weekPart },
  });
}
