import { Formik } from "formik";
import * as moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col,
  Form,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import {
  getWeeklyOverview,
  processData,
} from "../../services/dataApprovalService";
import { getProject } from "../../services/projectService";
import { DailyStatus } from "../../types/apiTypes";
import { calculateNextPrevWeek } from "../../utility/DateFilters";
import { Daily_status_types } from "../../utility/constants";
import useWeekPart from "../../utility/hooks/useWeekPart";
import Message from "../../utility/notifications/Message";
import Notify from "../../utility/notifications/Notify";
import DateToggler from "../components/Common/DateToggler";
import PageTitle from "../components/Common/PageTitle";
import { AppSignaturepad } from "../components/forms/AppSignaturepad";
import AppTextAreaInput from "../components/forms/AppTextAreaInput";
import AppTextInput from "../components/forms/AppTextInput";
import MessageTimeLineItem from "../components/timeline/MessageTimeLineItem";
import useQuickNavigationParams from "../../utility/hooks/useQuickNavigationParams";

const validationSchema = Yup.object().shape({
  needsPurchaseOrder: Yup.boolean(),
  purchaseOrder: Yup.string().when("needsPurchaseOrder", {
    is: true,
    then: Yup.string().required().nullable().label("Purchase order"),
  }),
  projectId: Yup.number().required(),
  comment: Yup.string().max(1000).label("Comment"),
  date: Yup.date().required(),
});

const ApproveData = () => {
  const [searchParams] = useSearchParams();
  const [index, setIndex] = useState("");
  const [weeklyOverviewData, setWeeklyOverviewData] = useState();
  const [loading, setLoading] = useState(true);
  const [dataEntryComplete, setDataEntryComplete] = useState(false);
  const [overviewDenied, setOverviewDenied] = useState(false);
  const [overviewApproved, setOverviewApproved] = useState(false);
  const [dateInfo, calculateWeekPart] = useWeekPart();

  const [overviewReadyForApproval, setOverviewReadyForApproval] =
    useState(false);
  const [project, setProject] = useState();

  const [week, setWeek] = useState();
  const [year, setYear] = useState();
  const [weekPart, setWeekPart] = useState();

  const { id: projectId } = useParams();

  const fetchData = useCallback(
    (year, week, weekPart) => {
      if (projectId) {
        getWeeklyOverviewData(parseInt(projectId), year, week, weekPart);
        getProjectData(parseInt(projectId));
      }
    },
    [projectId]
  );

  useQuickNavigationParams(fetchData);

  const getProjectData = async (id) => {
    await getProject(id).then((result) => {
      setProject(result.data.result);
    });
  };

  async function getWeeklyOverviewData(projectId, year, week, weekPart) {
    setOverviewDenied(false);
    setOverviewApproved(false);
    setOverviewReadyForApproval(true);

    try {
      const result = await getWeeklyOverview(projectId, year, week, weekPart);

      if (result) {
        setWeeklyOverviewData(result.data.result);
        const notEverythingDenied = result.data.result.days.find((element) => {
          if (element.status !== Daily_status_types.DeclinedByAreaManager)
            return true;
        });

        if (result.data.result.isApproved) {
          setOverviewApproved(true);
        }

        if (result.data.result.minDailyStatus === DailyStatus.Value14) {
        }

        const notEverythingApproved = result.data.result.days.find(
          (element) => {
            if (element.status !== Daily_status_types.ApprovedByAreaManager)
              return true;
          }
        );

        if (
          typeof notEverythingDenied == "undefined" &&
          result.data.result.days.length > 0 &&
          result.data.result.isDeclined
        ) {
          setOverviewDenied(true);
        }

        if (
          typeof notEverythingApproved == "undefined" &&
          result.data.result.days.length > 0
        ) {
          setOverviewApproved(true);
        }

        if (
          result.data.result.days.length !=
            result.data.result.totalDaysInWeek &&
          result.data.result.days.length > 0
        ) {
          setOverviewReadyForApproval(false);
        }
      }
    } catch (error) {
      setWeeklyOverviewData(undefined);
    }

    setYear(year);
    setWeek(week);
    setWeekPart(weekPart);
    setLoading(false);
  }

  useEffect(() => {
    if (dateInfo.selectedYear && dateInfo.selectedWeek && dateInfo.weekPart) {
      getWeeklyOverviewData(
        parseInt(projectId),
        dateInfo.selectedYear,
        dateInfo.selectedWeek,
        dateInfo.weekPart
      );
    }
  }, [dateInfo]);

  const handleDatePickerChange = (newDate) => {
    calculateWeekPart(newDate);
  };

  function toggle(newIndex) {
    if (newIndex === index) {
      setIndex("");
    } else {
      setIndex(newIndex);
    }
  }

  const handleSubmit = async (data) => {
    delete data.needsPurchaseOrder;
    data.overviewType = 0;
    await processData(data);
    Notify.success(`Data processed`);
    const updatedData = { ...weeklyOverviewData };
    if (data.isApproved) {
      updatedData.isApproved = true;
      setOverviewApproved(true);
    }
    setWeeklyOverviewData(updatedData);
    getWeeklyOverviewData(
      weeklyOverviewData.projectId,
      weeklyOverviewData.year,
      weeklyOverviewData.week,
      weeklyOverviewData.weekPart
    );
  };

  return (
    <>
      {loading && "Loading..."}

      {project && <PageTitle title={project?.name} customStyles="mb-2" />}

      <DateToggler
        title={
          weeklyOverviewData?.hasSecondWeekPart || weekPart === 2 ? (
            <span className="mx-3">
              week {week}.{weekPart}
            </span>
          ) : (
            <span className="mx-3">week {week}</span>
          )
        }
        handleDatePickerChange={(date) => handleDatePickerChange(date)}
        week={week}
        weekPart={weekPart}
        hasSecondWeekPart={weeklyOverviewData?.hasSecondWeekPart}
        year={year}
        handleDateToggle={(output) => {
          getWeeklyOverviewData(
            parseInt(projectId),
            output.year,
            output.week,
            output.weekPart
          );
        }}
      />

      {overviewDenied && weeklyOverviewData.days.length > 0 && (
        <div className="d-flex align-items-center p-3 my-3 text-white bg-secondary rounded shadow-sm">
          <div className="lh-1">
            <h1 className="h6 mb-0 text-white lh-1">Daily sheet was denied</h1>
            <small>
              This sheet has been denied, the housekeeper must re-send the data
            </small>
          </div>
        </div>
      )}

      {overviewApproved && weeklyOverviewData.days.length > 0 && (
        <div className="d-flex align-items-center p-3 my-3 text-white bg-secondary rounded shadow-sm">
          <div className="lh-1">
            <h1 className="h6 mb-0 text-white lh-1">
              Daily sheet was approved
            </h1>
            <small>
              This daily sheet was approved and is no longer accessible
            </small>
          </div>
        </div>
      )}

      {!overviewReadyForApproval && !overviewApproved && (
        <div className="d-flex align-items-center p-3 my-3 text-white bg-secondary rounded shadow-sm">
          <div className="lh-1">
            <h1 className="h6 mb-0 text-white lh-1">
              Daily sheet is not ready for sign-off
            </h1>
            <small>
              There are missing day's in the overview, please check with the
              housekeeper
            </small>
          </div>
        </div>
      )}

      {weeklyOverviewData &&
      weeklyOverviewData.totalItems &&
      weeklyOverviewData.days &&
      weeklyOverviewData.days.length > 0 ? (
        <>
          <div className="mb-4">
            <h4>Weekly total</h4>
            <hr></hr>
            <Row>
              <Col lg={8} sm={8}>
                <h6>Category</h6>
              </Col>
              <Col lg={4} sm={4}>
                <h6>Amount</h6>
              </Col>
            </Row>

            {weeklyOverviewData &&
              weeklyOverviewData.totalItems.map((item, i) => {
                return (
                  <Row className="mb-1" key={i}>
                    <Col lg={8} sm={8}>
                      {item.itemName}
                    </Col>
                    <Col lg={4} sm={4}>
                      {item.value}
                    </Col>
                  </Row>
                );
              })}
          </div>

          {weeklyOverviewData &&
            weeklyOverviewData.days.map((item, i) => {
              return (
                <Accordion open={index} toggle={toggle} key={i}>
                  <AccordionItem className="mb-3">
                    <AccordionHeader targetId={`accordionItem${i}`}>
                      {moment(item.date).format("dddd | DD-MM-yyyy")}
                    </AccordionHeader>
                    <AccordionBody accordionId={`accordionItem${i}`}>
                      <Row>
                        <Col lg={8} sm={8}>
                          <h6>Category</h6>
                        </Col>
                        <Col lg={4} sm={4}>
                          <h6>Amount</h6>
                        </Col>
                      </Row>

                      {item.items &&
                        item.items.map((dayRecord, i) => {
                          return (
                            <Row className="mb-1" key={i}>
                              <Col lg={8} sm={8}>
                                {dayRecord.itemName}
                              </Col>
                              <Col lg={4} sm={4}>
                                {dayRecord.value}
                              </Col>
                            </Row>
                          );
                        })}
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              );
            })}

          <Formik
            enableReinitialize={true}
            initialValues={{
              purchaseOrder: weeklyOverviewData.purchaseOrder ?? "",
              projectId: weeklyOverviewData.projectId,
              date: weeklyOverviewData.days[0].date,
              comment: weeklyOverviewData.comment ?? "",
              signature: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} className="pb-5">
                  {weeklyOverviewData.comments && (
                    <>
                      <h5>Comments</h5>
                      {weeklyOverviewData.comments?.map((comment) => (
                        <MessageTimeLineItem
                          action={comment.type}
                          userName={comment.userName}
                          isAdditionalRequest={comment.isAdditionalRequest}
                          value={comment.value}
                          creationTime={new Date(comment.creationTime)}
                        />
                      ))}
                    </>
                  )}

                  {weeklyOverviewData.needsPurchaseOrder && (
                    <Row>
                      <Col sm={12}>
                        <div className="mb-3">
                          <AppTextInput
                            type="text"
                            name="purchaseOrder"
                            label="Purchaseorder"
                            required
                            disabled={overviewApproved || overviewDenied}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  {weeklyOverviewData?.minDailyStatus ===
                    DailyStatus.Value14 && (
                    <>
                      {!overviewApproved && !overviewDenied && (
                        <Row className="mb-4">
                          <Col sm={12}>
                            <AppTextAreaInput
                              name="comment"
                              label="Notes"
                              rows="6"
                              disabled={overviewApproved || overviewDenied}
                            />
                          </Col>
                        </Row>
                      )}

                      {(!weeklyOverviewData.isApproved || !overviewApproved) &&
                        overviewReadyForApproval &&
                        !overviewDenied && (
                          <>
                            <Row>
                              <Col className="mb-4">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value="1"
                                  onClick={(e) => {
                                    setDataEntryComplete(e.target.checked);
                                  }}
                                  id="dataEntryCorrect"
                                />

                                <label
                                  for="dataEntryCorrect"
                                  className="form-check-label ms-2"
                                >
                                  To my knowledge, this form has been filled in
                                  correctly
                                </label>
                              </Col>
                            </Row>

                            <AppSignaturepad
                              setFieldValue={setFieldValue}
                              name="signature"
                              label="Signed by (official title)"
                            />

                            <Row className="text-end pt-5">
                              <Col>
                                <Button
                                  color="danger"
                                  size="lg"
                                  disabled={isSubmitting}
                                  className="me-3"
                                  onClick={(e) => {
                                    setFieldValue("isApproved", false);

                                    if (
                                      values.comment == "" &&
                                      values.comment != null
                                    ) {
                                      Message(
                                        "Comment is required when you decline a daily"
                                      );
                                    } else {
                                      handleSubmit(e);
                                    }
                                  }}
                                >
                                  Decline overview
                                </Button>

                                <Button
                                  color="primary"
                                  size="lg"
                                  disabled={
                                    isSubmitting ||
                                    !dataEntryComplete ||
                                    !values.signature
                                  }
                                  onClick={(e) => {
                                    setFieldValue("isApproved", true);
                                    handleSubmit(e);
                                  }}
                                >
                                  Approve overview
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                    </>
                  )}
                </Form>
              </>
            )}
          </Formik>
        </>
      ) : (
        <div className="d-flex align-items-center p-3 my-3 text-white bg-secondary rounded shadow-sm">
          <div className="lh-1">
            <h1 className="h6 mb-0 text-white lh-1">
              Administration has not yet been submitted
            </h1>
            <small>This period has not been provided by the housekeeper</small>
          </div>
        </div>
      )}
    </>
  );
};

export default ApproveData;
